import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AnimEn = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      music: file(relativePath: { eq: "mascotteMusique.png" }) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      disque: file(relativePath: { eq: "disque.png" }) {
        childImageSharp {
          fixed(width: 87) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cle: file(relativePath: { eq: "cle.png" }) {
        childImageSharp {
          fixed(width: 113) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cassette: file(relativePath: { eq: "cassette.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      danse: file(relativePath: { eq: "danse.png" }) {
        childImageSharp {
          fixed(width: 146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      boule: file(relativePath: { eq: "boule.png" }) {
        childImageSharp {
          fixed(width: 146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      quizz: file(relativePath: { eq: "quizz.png" }) {
        childImageSharp {
          fixed(width: 234) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chanter: file(relativePath: { eq: "chanter.png" }) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ballons: file(relativePath: { eq: "ballons.png" }) {
        childImageSharp {
          fixed(width: 123) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mariage: file(relativePath: { eq: "mariage.png" }) {
        childImageSharp {
          fixed(width: 117) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      thes: file(relativePath: { eq: "thes.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      loto: file(relativePath: { eq: "loto.png" }) {
        childImageSharp {
          fixed(width: 504) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      micro: file(relativePath: { eq: "micro.jpg" }) {
        childImageSharp {
          fixed(width: 146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      enceinte: file(relativePath: { eq: "enceinte.png" }) {
        childImageSharp {
          fixed(width: 68) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout lang="en" location={location}>
      <SEO
        title="Musical entertainment"
        description="Evening parties, karaoke, birthdays, weddings, tea dances, lotto, event sound system"
        lang="en"
      />
      <div className="submenu">
        <a href="#les_soiree">Dances parties</a>
        <a href="#karaoke">Karaoke</a>
        <a href="#anniversaires">Birthdays - Wedding - Dancing Tea</a>
        <a href="#event">Sound of your events</a>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-3">
          <Img
            fluid={data.music.childImageSharp.fluid}
            alt="Animation musicale"
            style={{ maxWidth: 260, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            My passion for music is very old. I have been immersed in music
            since my childhood.
          </p>
          <p>
            My father has long had a taste for choral singing, first classical
            and then Jazz. Already child impossible to miss one of his concerts,
            where it was not uncommon for me to be in the front row.
          </p>
          <p>
            The Sunday meals at my grandparents' house were also often
            punctuated by local Comtois songs.
          </p>
          <div className="columns is-vcentered">
            <div className="column is-2 has-text-centered">
              <Img
                fixed={data.disque.childImageSharp.fixed}
                alt="Disque"
                style={{ maxWidth: 87, width: "100%" }}
              />
            </div>
            <div className="column">
              <p>
                As a teenager, I discover with emotion the joys of radio,
                including «radio Lacuzon». Nostalgic memories of the K7 readers
                that we sometimes rewind with our bic pencils, and listened to
                with our walkers. Multiple universes were opening up, first
                stroke for Abba, very quickly the rocknroll with Elvis, as well
                as the vinyl record of the Black Socks had my preference.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.cle.childImageSharp.fixed}
            alt="Clé USB"
            style={{ maxWidth: 113, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            I’ve always listened to the radio a lot no matter what the
            circumstances.
          </p>
          <p>
            As an adult, I enrolled in a choir, and I am still at one of them
            close to my home.
          </p>
          <p>
            I go to concerts very regularly and I like to discover new genres
            and styles.
          </p>
          <p>
            My discotheque is huge, it will adapt to your tastes and requests.
          </p>
        </div>
        <div className="column is-2">
          <Img
            fixed={data.cassette.childImageSharp.fixed}
            alt="Cassette"
            style={{ maxWidth: 100, width: "100%" }}
          />
        </div>
      </div>
      <h2 id="les_soiree">Dances parties</h2>
      <div className="columns is-vcentered">
        <div className="column">
          <p>
            Thanks to a professional equipment, I can make you dance all along
            the night, with different styles of music : walz, tango, dance,
            rock'n'roll…
          </p>
          <p>
            My aim is to satisfy you and to make this party a very pleasant and
            festive moment
          </p>
        </div>
        <div className="column is-3">
          <Img
            fixed={data.danse.childImageSharp.fixed}
            alt="Danse"
            style={{ maxWidth: 146, width: "100%" }}
          />
        </div>
      </div>
      <h3>Eighties 80'</h3>
      <div className="has-text-centered mb-2 mt-2">
        <Img
          fixed={data.boule.childImageSharp.fixed}
          alt="Boule"
          style={{ maxWidth: 146, width: "100%" }}
        />
      </div>
      <p>
        A prolific period in music production, everyone loves these tubes filled
        with happy or nostalgic memories. No doubt you will be drawn by the
        demons of midnight or dance. Some offences will still be planned in
        previous years and those of today. A hurricane blows on the dance floor,
        but as the music is good no need of a pinball because everything rolls
        because it is the cotton wool she prefers!
      </p>
      <h3 className="mt-6">Games and Quizz</h3>
      <div className="has-text-centered mb-2 mt-2">
        <Img
          fixed={data.quizz.childImageSharp.fixed}
          alt="quizz"
          style={{ maxWidth: 234, width: "100%" }}
        />
      </div>
      <p>
        If you are unbeatable on Tv series, cartoons, films or other topics,
        this is for you, regardless of your tastes or your age.
      </p>
      <h2 className="mt-6" id="karaoke">
        Karaoke
      </h2>
      <div className="columns is-vcentered">
        <div className="column is-2">
          <Img
            fixed={data.chanter.childImageSharp.fixed}
            alt="chanter"
            style={{ maxWidth: 140, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>Whenever you know how to sing or not, it is the opportunity</p>
          <p>to share a very funny moment with friends, collegues, family…</p>
          <p>Everybody can sing these famous hits or standards.</p>
        </div>
      </div>
      <div className="columns is-multiline" style={{justifyContent: "center"}}>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/avrYKotSw4w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/ubOo0p-wS3k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/OFtH6YXU0Q0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/rk_JYt6N0a8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/6W7hOj4VjXM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/N_-j1s_Zx-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/YVFLvKRloGc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/6wD_SpEknso" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/gEdw-Gf9_-I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/DaL9mH7twzU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <h2 className="mt-5" id="anniversaires">
        Birthdays-Wedding-Dancing Tea
      </h2>
      <div className="columns is-vcentered mt-3">
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/PHJfDQGOeY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/ix7Fo92JDM4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/QOQKmOEllXY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column ml-6 mr-6">
          <p>
            There are pleasant moments in our lives, with your family and
            friends.
          </p>
          <p>
            I adapt to you musical tasted and to your needs, with a calm and
            cosy tone during the meal and a festive surrounding on the dance
            floor.
          </p>
          <p>I can go in the whole Jura departments.</p>
          <p>
            I have a catalogue of more than 10 000 titles, a screen, a video
            projector.
          </p>
          <p>
            An entertainer must be able to adapt to different kinds of
            surroundings from the lively athmosphere of a dancing night to the
            more quiet atmosphere of a loto during which numbers must be clearly
            heard.
          </p>
        </div>
        <div className="column is-2">
          <Img
            fixed={data.ballons.childImageSharp.fixed}
            alt="ballons"
            style={{ maxWidth: 123, width: "100%" }}
          />
        </div>
      </div>
      <h2 className="mt-6" id="event">
        Sound of your events
      </h2>
      <div className="columns is-vcentered mt-2">
        <div className="column is-2">
          <Img
            fixed={data.micro.childImageSharp.fixed}
            alt="micro"
            style={{ maxWidth: 226, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            I put my experience at your service for all the important moments of
            your life.
          </p>
          <p>
            Thanks to a branded sound system HK, made in Germany, you will
            benefit of a high standard quality of sound. No need to push the
            volume up too much, it gives a clear and warm sound during speeches,
            awards, farewells…
          </p>
          <p>
            I can also provide microphones wireless or not, and a microphone
            stand.
          </p>
        </div>
        <div className="column is-2">
          <Img
            fixed={data.enceinte.childImageSharp.fixed}
            alt="enceinte"
            style={{ maxWidth: 68, width: "100%" }}
          />
        </div>
      </div>
      <div className="mt-6" style={{ fontFamily: "made", fontSize: 30 }}>
        <Link to="/en/agenda/">☞ My futures benefits</Link>
      </div>
    </Layout>
  )
}

export default AnimEn
